/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    easel: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0a.5.5 0 01.473.337L9.046 2H14a1 1 0 011 1v7a1 1 0 01-1 1h-1.85l1.323 3.837a.5.5 0 11-.946.326L11.092 11H8.5v3a.5.5 0 01-1 0v-3H4.908l-1.435 4.163a.5.5 0 11-.946-.326L3.85 11H2a1 1 0 01-1-1V3a1 1 0 011-1h4.954L7.527.337A.5.5 0 018 0M2 3v7h12V3z"/>',
    },
});
